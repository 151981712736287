<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-title v-if="title">
        <span class="text-h3">{{ $t(title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-if="authUser.isAdmin"
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="options.product"
                dense
                :disabled="type == 'edit'"
                :items="['oem', 'cso']"
                item-text="name"
                item-value="id"
                :label="$t('select_product_*')"
                :rules="[rules.required]"
                @change="tenantList"
              />
            </v-col>
            <v-col
              v-if="authUser.isAdmin"
              cols="6"
              sm="6"
            >
              <v-autocomplete
                v-model="options.tenant_id"
                dense
                :loading="tenantLoading"
                :items="options.product
                  ? options.product == 'oem'
                    ? tenantsOEM
                    : tenantsCSO
                  : []
                "
                :disabled="type == 'edit'"
                item-text="name"
                item-value="id"
                :label="$t('select_tenant_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.location_id"
                dense
                :disabled="readOnly"
                :loading="locationLoading"
                :items="options.tenant_id ? locationsList : []"
                item-value="id"
                item-text="name"
                :label="$t('select_location_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.latitude"
                dense
                :disabled="true"
                :label="$t('latitude_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.longitude"
                dense
                :disabled="true"
                :label="$t('longitude_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.status"
                dense
                :disabled="readOnly"
                :items="statusOptions"
                item-value="id"
                item-text="name"
                :label="$t('status_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.type"
                dense
                :items="typeOptions"
                item-value="id"
                item-text="name"
                :label="$t('type') + ' *'"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.is_private"
                dense
                :disabled="readOnly"
                :items="accessLevelOptions"
                item-value="id"
                item-text="name"
                :label="$t('access_level')"
              />
            </v-col>
            <v-col
              v-if="options.status == 0"
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.unavailability_reason"
                dense
                :disabled="readOnly"
                :label="$t('unavailability_reason_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.notes"
                dense
                :disabled="readOnly"
                :label="$t('notes')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="options.manufacturer"
                dense
                :disabled="readOnly"
                :label="$t('manufacturer_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="options.model"
                dense
                :disabled="readOnly"
                :label="$t('model_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="options.serial_number"
                dense
                :disabled="readOnly"
                :label="$t('serial_no_*')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="12">
              <div class="text-h3 d-flex justify-space-between align-center">
                {{ $t("connectors") }}
                <v-btn
                  v-if="!readOnly"
                  color="primary"
                  dark
                  small
                  @click="addConnectors"
                >
                  {{ $t("add_new") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <div
            v-for="(row, i) in options.charger_connectors"
            :key="i"
            class="d-flex justify-space-between align-center mt-2"
          >
            <div class="connectorsRow">
              <div class="field">
                <v-select
                  v-model="options.charger_connectors[i].connector_type"
                  dense
                  :items="connectorType"
                  item-value="id"
                  item-text="name"
                  :disabled="readOnly"
                  :label="$t('connector_type_*')"
                  :rules="[rules.required]"
                />
              </div>
              <div class="field">
                <v-select
                  v-model="options.charger_connectors[i].connector_format"
                  dense
                  :items="connectorFormat"
                  item-value="id"
                  item-text="name"
                  :disabled="readOnly"
                  :label="$t('connector_format_*')"
                  :rules="[rules.required]"
                />
              </div>
              <div class="field">
                <v-select
                  v-model="options.charger_connectors[i].power_type"
                  dense
                  :items="connectorPowerType"
                  item-value="id"
                  item-text="name"
                  :disabled="readOnly"
                  :label="$t('power_type_*')"
                  :rules="[rules.required]"
                />
              </div>
              <div class="field">
                <v-text-field
                  v-model="options.charger_connectors[i].power"
                  dense
                  :disabled="readOnly"
                  :label="$t('power_*')"
                  :rules="[rules.required, rules.number]"
                />
              </div>
              <div class="field">
                <v-select
                  v-model="options.charger_connectors[i].rate_id"
                  dense
                  :loading="ratesLoading"
                  :items="options.tenant_id ? ratesList : []"
                  item-value="id"
                  item-text="name"
                  :disabled="readOnly"
                  :label="$t('select_rate_*')"
                  :rules="[rules.required]"
                />
              </div>
            </div>

            <div
              v-if="i !== 0 && !readOnly"
              class="deleteButton"
            >
              <v-icon
                class="deleteIcon"
                @click="deleteRow(i)"
              >
                mdi-delete
              </v-icon>
            </div>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small v-if="!readOnly">{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!readOnly"
          color="primary"
          text
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  mixins: [validationRules],

  props: {
    type: {
      type: String,
      default() {
        return {
          time: null,
        };
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tenantOEM: [],
      options: {
        charger_connectors: [
          {
            connector_type: null,
            connector_format: null,
            power_type: null,
            power: null,
            rate_id: null,
          },
        ],
      },
      typeOptions: [
        {
          id: "AC",
          name: "AC",
        },
        {
          id: "DC",
          name: "DC",
        },
        {
          id: "AC/DC",
          name: "AC/DC",
        },
      ],
      statusOptions: [
        {
          id: "1",
          name: "active",
        },
        {
          id: "0",
          name: "inactive",
        },
      ],
      accessLevelOptions: [
        {
          id: 0,
          name: "Public",
        },
        {
          id: 1,
          name: "Private",
        },
      ],
      chargeStationType: [
        { id: "physical", name: "Physical" },
        { id: "simulator", name: "Simulator" },
      ],
      connectorType: [
        { id: "CSS", name: "CSS" },
        { id: "CHAdeMO", name: "CHAdeMO" },
      ],
      connectorFormat: [
        { id: "cable", name: "Cable" },
        { id: "socket", name: "Socket" },
      ],

      connectorPowerType: [
        { id: "acsp", name: "AC Single Phase" },
        { id: "actp", name: "AC Three Phase" },
        { id: "dc", name: "DC" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantOptions",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsCSO: "tenantsManagement/getTenantsCSO",
      //
      locationLoading: "csmsLocations/getLoading",
      locationsList: "csmsLocations/getList",
      ratesLoading: "csmsRates/getLoading",
      ratesList: "csmsRates/getList",
    }),
    title() {
      if (this.readOnly) {
        return "view";
      }
      if (this.type == "create") {
        return "add_new";
      } else if (this.type == "edit") {
        return "edit";
      } else {
        return "";
      }
    },
  },
  watch: {
    "options.tenant_id": {
      handler: async function (val) {
        if (this.type == "create") this.fetchDependents(val);
      },
    },
    "options.location_id": {
      handler: async function (val) {
        const arr = this.locationsList.find((r) => r.id == val);
        this.options.latitude = arr?.latitude;
        this.options.longitude = arr?.longitude;
      },
    },
  },
  async mounted() {
    if (this.type == "create") {
      if (!this.authUser.isAdmin) {
        this.options.tenant_id = this.authUser.tenant_id;
        this.fetchDependents(this.authUser.tenant_id);
      }
      // this.options.currency = "PKR";
    }

    if (this.type == "edit") {
      this.options = { ...this.item };
      await this.$store
        .dispatch("csmsLocations/list", {
          tenant_id: this.options.tenant_id,
        })
        .then(() => {
          if (this.options.location_id) {
            const arr = this.locationsList.find(
              (r) => r.id == this.options.location_id
            );
            this.options.latitude = arr?.latitude;
            this.options.longitude = arr?.longitude;
          }
        });
      await this.$store.dispatch("csmsRates/list", {
        tenant_id: this.options.tenant_id,
      });
    }
  },
  methods: {
    tenantList() {
      if (this.product == "oem") {
        this.tenantOEM = this.tenantsList.filter((r) => r.product == "oem");
      } else {
        this.tenantOEM = this.tenantsList.filter((r) => r.product == "cso");
      }
    },
    saveItem() {
      if (this.$refs.form.validate()) {
        const opt = { ...this.options, station_type: "physical" };
        this.$emit("onsave", opt);
      }
    },
    closeForm() {
      // this.$refs.form.reset();
      this.$emit("onclose");
    },

    async fetchDependents(tId) {
      // delete this.options.location_id;
      delete this.options.latitude;
      delete this.options.longitude;
      await this.$store
        .dispatch("csmsLocations/list", {
          tenant_id: tId,
        })
        .then(() => {
          if (this.options.location_id) {
            const arr = this.locationsList.find(
              (r) => r.id == this.options.location_id
            );
            this.options.latitude = arr?.latitude;
            this.options.longitude = arr?.longitude;
          }
        });
      await this.$store.dispatch("csmsLocations/list", { tenant_id: tId });
      await this.$store.dispatch("csmsRates/list", { tenant_id: tId });

    },
    addConnectors() {
      this.options.charger_connectors.push({
        connector_type: null,
        connector_format: null,
        power_type: null,
        power: null,
        rate_id: null,
      });
    },
    deleteRow(index) {
      this.options.charger_connectors.splice(index, 1);
    },
    //
  },
};
</script>
<style lang="sass" scoped>
.connectorsRow

  display: flex
  gap:1rem
  .field
    min-width:150px !important
    max-width:165px !important


.deleteButton
  cursor: pointer
  .deleteIcon
    &:hover
      color: red
</style>